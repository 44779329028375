import app_store from './images/app_store.svg';
import React, { useRef } from 'react';
import Footer from './Footer';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import screen from './images/screen.jpg';
import ig_logo_big from './images/ig_logo_big.svg';

const useStyles = makeStyles((theme) => ({
  content: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
    margin: "0 10%",
    flexGrow: 1,
  },
  title: {
    color: '#D4DDFF',
    fontWeight: '600',
  },
  titleTwo: {
    color: '#8DA8F1',
    fontWeight: '600',
  },
  titleThree: {
    color: '#F5C4F6',
    fontWeight: '600',
  },
  p: {
    color: '#FFFFFF',
  },
  img: {
    marginTop: '16px',
    width: 'auto',
    height: '55px',
  }
}));

const About = () => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const matches = useMediaQuery('(min-width:525px)');

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <div className={classes.content}>


        <Typography variant="h5" className={classes.title}>
          Start with friendship and the best way to connect with new people.
        </Typography>

        <Typography className={classes.p}>
          ☺︎ We believe in optimizing your time, <i>not</i> hijacking your attention.
        </Typography>

        <Typography className={classes.p}>
          ☺︎ We believe the basis of connection is shared interests, <i>not</i> shared beauty.
        </Typography>

        <Typography className={classes.p}>
          ☺︎ We believe you should meet people through the online communities and brands you <i>already</i> share a deeper identity to.
        </Typography>

        <Typography className={classes.p}>
          ☺︎ We believe in 1-on-1 and depth over breadth. You don’t need another overcrowded message board or flooded content feed.
        </Typography>

        <Typography variant="h5" className={classes.titleTwo} style={{ marginTop: "32px" }}>
          End with a better you and world.
        </Typography>

        <Typography className={classes.p}>
          ☺︎ We believe the joy in interpersonal relationships and making a routine to meet new people outside your routine bubble is how we <i>really</i> end the repeating history of division, hate, and prejudice. - <a href="https://youtu.be/ggauZX0zJpo?t=112"><u><b>hear it from Oprah herself.</b></u></a>
        </Typography>

        <Typography variant="h5" className={classes.titleThree} style={{ marginTop: "32px" }}>
          Join the Pretzly family. Follow on IG for the latest.
        </Typography>

        <Box textAlign="center">
          <a href="https://www.instagram.com/thepretzlycompany/">
            <img alt="appstore-logo" className={classes.img} src={app_store} />
          </a>
        </Box>
      </div>

      <Footer showPretzels={true}/>
    </Box>
  );
};

export default About;
