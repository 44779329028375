import Header from './Header';
import Home from './Home';
import Privacy from './Privacy';
import About from './About';
import Terms from './Terms';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
  adaptV4Theme,
} from '@mui/material/styles';

let theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#D4DDFF',
    },
    secondary: {
      main: '#8DA8F1',
    },
    pink: {
      main: '#F5C4F6',
    },
    palePink: {
      main: '#FFD3D9',
    },
  },
  typography: {
    fontFamily: `"Satoshi", sans-serif`,
    body1: {
      color: 'RGB(172,192,247)',
    },
    h1: {
      fontFamily: `"Satoshi", sans-serif`,
      fontSize: '6rem',
      fontWeight: 600,
    },
  },
}));
theme = responsiveFontSizes(theme, {
  factor: 4,
});

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/privacy/">
            <Privacy />
          </Route>
          {/* Disable /about page for now. */}
          {/* 
            <Route path="/about/">
              <About />
            </Route> 
          */}
          <Route path="/terms/">
            <Terms />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default App;
