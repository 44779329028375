import logo from './images/logo.svg';
import ig_logo from './images/ig_logo.svg';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    flex: '0 0 auto',
    margin: '40px 10%'
  },
  logo: {
    display: 'block',
    height: 'auto',
    width: '125px',
  },
  igLogo: {
    display: 'block',
    height: 'auto',
    width: '25px',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}));

const Header = () => {
  const classes = useStyles(); 

  return (
    <Box className={classes.root} display="flex" justifyContent="flex-end">
      <Box mr="auto">
        <Link className={classes.logo} to="/">
          <img className={classes.img} src={logo} alt="logo" />
        </Link>
      </Box>
      <Box>
        <a className={classes.igLogo} href="https://www.instagram.com/thepretzlycompany/" target="_blank">
          <img className={classes.img} src={ig_logo} alt="ig-logo" />
        </a>
      </Box>
    </Box>
  );
};

export default Header;