import React from 'react';

const TermsContent = () => (
  <div style={{ color: 'RGB(172, 192, 247)' }}>
    <p>
        Welcome, and thank you for your interest in the website www.pretzly.com (the "Website") and our
        mobile application
        Pretzly (the "App"), together hereafter referred to in these Terms of Service as "Pretzly", "us",
        "our" or "we". Pretzly
        is owned and operated by Pretzly Inc, a Delaware Incorporated Company, and for the purposes
        of this Agreement and
        our Privacy Policy any use of the terms "Pretzly", "us", "our" or "we" includes Pretzly Inc, without
        limitation. Unless
        otherwise specified, all references to our services (the “Service” or "Services") include the
        content, services and
        products available through the Pretzly Website or App, as well as any software that Pretzly provides
        to you that allows
        you to access the Services. The term "user", "you" or "your" refers to the user of the Service,
        including visitors that
        do not register for an account. The following Terms of Service are a legally binding contract
        between you and Pretzly
        regarding your use of the Service.
              </p>
      <p>
        Please read the following Terms of Service ("Terms" or "Agreement") carefully before accessing or
        using any of the
        Services. Each time you access or use our Services or purchase something from us, you, and if you
        are acting on behalf
        of a third party or your employer, such third party or employer, agree to be bound by these Terms of
        Service and our
        Privacy Policy whether or not you register with us. If you do not agree to be bound by all of these
        Terms, you may not
        access or use our Service. Pretzly may change this Agreement at any time by posting an updated Terms
        of Service on this
        site. If any amendment to these Terms is unacceptable to you, you shall cease using this Site or
        App. If you continue
                  using the Site or the App, you will be conclusively deemed to have accepted the changes.</p>
      <p>
        In addition, certain areas of the Service may be subject to additional Terms of Service that we make
        available for your
        review. By using such areas, or any part thereof, you are expressly indicating that you have read
        and agree to be bound
        by the additional Terms of Service applicable to such areas. In the event that any of the additional
        Terms of Service
                  governing such area conflict with these Terms, the additional terms will control.<p><b>PLEASE READ
        THE BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER PROVISIONS IN THE DISPUTE RESOLUTION
        SECTION OF THESE
        TERMS. IT AFFECTS HOW DISPUTES ARE RESOLVED.BY ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY
        ACKNOWLEDGE THAT YOU
        UNDERSTAND THIS AGREEMENT, INCLUDING THE DISPUTE RESOLUTION, ARBITRATION PROVISIONS AND
        CLASS ACTION WAIVER AND ACCEPT
        ALL OF THE TERMS. YOU MAY NOT USE OR ACCESS OUR PLATFORM IF YOU DO NOT AGREE TO BE BOUND BY
        THE TERMS AND CONDITIONS OF
                          THIS AGREEMENT.</b>
        </p>





        <h3> 1. Eligibility for Our Service </h3>

        <p>
          By using our Services, you represent and warrant that:
                      <ol class="text" type="a">
            <li> You have attained the age of majority where you reside (eighteen (18) years of age or
            older in most jurisdictions)
            and are otherwise capable of entering into binding contracts including this Agreement
            and
            the terms, conditions,
                              representations and warranties set forth in the Terms.</li>
            <li> You have never been convicted of or pleaded no contest to a felony, a sex crime, or any
            crime involving violence, and
            that you are not required to register as a sex offender with any state, federal or local
            sex
                              offender registry.</li>
            <li> You are not a person who is barred from using our Service under the laws of the United
            States or any other applicable
                              state, local or foreign jurisdiction.</li>
            <li> If you are using our Services on behalf of a company or other organization, you
            represent
            and warrant that you have
            authority to act on behalf of that entity and to bind that entity to this Agreement.
                          </li>
          </ol>
        </p>


        <h3> 2. Description of Service </h3>

        <p>

          <ol type="a">
            <li> Pretzly is a social networking mobile application where users connect through our
            Service
            and thereafter can choose
            to meet in-person to have a casual dialogue on an agreed-upon shared topic or question
            of
            interest. A user can either
            post their own topic or question for other local users to consider and respond to if
            they
            have an interest in doing so,
            or view a posts by other local users and make a similar decision to engage by
            responding.
            User thereafter may choose to
                              send or accept a virtual invitation to meet up at a mutually agreeable location.</li>
            <li> The Service does not include any software application or service that is provided by
            you or
            a third party, which you
                              use in connection with the Service.</li>
            <li> Any modifications and new features added to the Service are also subject to this
            Agreement.
                          </li>
            <li> Pretzly reserves the right to modify or discontinue the Service or any feature or
            functionality thereof at any time
            without notice to you. All rights, title and interest in and to the Service and its
            components (including all
                              intellectual property rights) will remain with and belong exclusively to Pretzly.</li>
          </ol>
        </p>



        <h3> 3. Accounts and Registration </h3>

        <p>
          <ol type="a">
            <li> To access some features of the Service, you may be required to register for an account.
            When you register
            for an
            account, you may be required to provide us with some information about yourself (such as
            your name, date of
            birth,
            e-mail address, physical address, phone number or other personal information). Some of
            this
            information may
            be of a
            confidential nature and may include personal identifying information (all "Your
                              Information").</li>
            <li> If you provide Your Information to us then you agree to provide true, current, complete
            and
            accurate
            information, and
            not to misrepresent your identity. You also agree to keep Your Information current and
            to
            update Your
            Information if any
                              of Your Information changes.</li>
            <li> Our collection, use and disclosure of Your Information is governed by this Agreement
            and
            our Privacy Policy
            which you
                              may access here <a href="privacy">(https://pretzly.com/privacy).</a></li>
          </ol>
        </p>


        <h3> 4. Account Management </h3>

        <p>
          <ol type="a">
            <li><b> Keep Your Password Secure.</b> If you have been issued an account by Pretzly in
                              connection with your use of the Services,
                              you are responsible for safeguarding your password and any other credentials used to
                              access
                              that account, even if you
                              authorize other parties in your organization to access your account. You, and not
                              Pretzly,
                              are responsible for any
                              activity occurring in your account, whether or not you authorized that activity. If you
                              become aware of any unauthorized
                              access to your account, you should notify Pretzly immediately.</li>
            <li><b>Keep Your Details Accurate.</b> Pretzly may send notices to the email address or text
                              messages to your mobile phone
                              registered with your account. You must keep your email address, mobile phone number and,
                              where applicable, your contact
                              details and payment details associated with your account current and accurate.</li>
            <li> We reserve the right to modify, suspend or terminate the Service, any user account or
            your
            access to the Service for
                              any reason, without notice, at any time, and without liability to you.</li>
            <li> You can cancel your account at anytime. Upon termination or cancellation, all licenses
            and
            other rights granted to
                              you in these Terms will immediately cease.</li>
            <li> We reserve the right to refuse to issue an account to anyone or permit access to the
            Service to anyone for any reason
                              at any time.</li>
          </ol>
        </p>

        <h3> 5.Your Access and Use of our Services </h3>

        <p>
          <ol type="a">
            <li>Your right to access and use our Services is personal to you and is not transferable by
            you to any other person or
            entity. Access to our Services may not be available in all locations. You are only
            entitled
            to access and use our
            Services for lawful purposes and pursuant to the terms and conditions of this Agreement
            and
            our Privacy Policy. Any
            action by you that, in our sole discretion: (i) violates the terms and conditions of
            this
            Agreement and/or the Privacy
            Policy; (ii) restricts, inhibits or prevents any access, use or enjoyment of our
            Services;
            or (iii) through the use of
            our Services, defames, abuses, harasses, offends or threatens others, shall not be
            permitted, and may result in your
                              loss of the right to access and use our Services.</li>
            <li>The rights granted to you in these Terms are subject to the following restrictions: (i)
            you shall not license, sell,
            rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the
            Services; (ii) you shall not
            modify, make derivative works of, disassemble, reverse compile or reverse engineer any
            part
            of the Services; (iii) you
            shall not access the Services in order to build a similar or competitive Service; and
            (iv)
            except as expressly stated
            herein, no part of the Services may be copied, reproduced, distributed, republished,
            downloaded, displayed, posted or
            transmitted in any form or by any means. Any future release, update, or other addition
            to
            functionality of the Services
                              shall be subject to these Terms.</li>
            <li> Furthermore, you agree that you will not use any robot, spider, scraper, deep link or
            other
            similar automated data
            gathering or extraction tools, program, algorithm or methodology to access, acquire,
            copy or
            monitor our Services or any
            portion of our Services or for any other purpose, without our prior written permission.
            Additionally, you agree that you
            will not: (i) take any action that imposes, or may impose in our sole discretion an
            unreasonable or disproportionately
            large load on our infrastructure; (ii) copy, reproduce, modify, create derivative works
            from, distribute or publicly
            display any content (except for your personal information) from our Services without our
            prior written permission and
            the appropriate third party, as applicable; (iii) interfere or attempt to interfere with
            the
            proper working of our
            Services or any activities conducted on our Services; (iv) bypass any robot exclusion
            headers or other measures we may
            use to prevent or restrict access to our Services, or (v) interfere or disrupt the
            Service
            or servers or networks
            connected to the Service, including by transmitting any worms, viruses, spyware, malware
            or
            any other code of a
                              destructive or disruptive nature.</li>
            <li>Except as expressly permitted in this Agreement, you shall not collect or harvest any
            personally identifiable
                              information, including account names, from our Services.</li>
            <li> Our Services may now, or in the future, have "publicly accessible areas" that allow
            users
            to post User Content
            (hereafter defined) that will be accessible by the public or the user population
            generally.
            As a user of the Services,
            you acknowledge and affirmatively agree that in the course of using the Services you may
            be
            exposed to User Content that
            might be offensive, harmful, inaccurate or otherwise inappropriate. You further agree
            that
            Pretzly shall not, under any
                              circumstances, be liable in any way for any User Content.</li>
            <li> You understand that Pretzly may issue upgrade versions of any mobile App and you
            consent
            to receiving automatic
            upgrades on your mobile device. You also acknowledge and agree that standard carrier
            data
            charges may apply to your use
                              of the Service including, without limitation, text messages.</li>
            <li> You shall not use any communication systems provided on our Services including, without
            limitation email, for any
            commercial or solicitation purposes. You shall not solicit for commercial purposes any
            users
            of our Services without our
                              prior written permission.</li>
            <li> You understand and agree that you are solely responsible for compliance with any and
            all
            laws, rules and regulations
                              that may apply to your use of the Services.</li>
          </ol>
        </p>

        <h3>6. Suspension and Termination of Services </h3>

        <p>
          <ol type="a">
            <li> Pretzly may limit or suspend or terminate the Services to you if you fail to comply
            with
            these Terms, the Privacy
            Policy or if you use the Services in a way that causes legal liability to us or disrupts
            use
            of the Service by other
                              users.</li>
            <li> Pretzly may also suspend providing the Services to you if we are investigating
            suspected
            misconduct by you. Pretzly
            will use commercially reasonable efforts to narrow the scope and duration of any
            limitation
            or suspension under this
                              Section as is needed to resolve the issue that prompted such action.</li>

          </ol>
        </p>


        <h3>7. Information Accuracy </h3>

        <p>
          <ol type="a">
            <li> We attempt to ensure that information on this Service is complete, accurate and
            current.
            Despite our best efforts,
            the information on our Service may occasionally be inaccurate, incomplete or out of
            date. We
            make no representation as
                              to the completeness, accuracy, or currency of any information on the Service.</li>
            <li> Furthermore, information on the Service may contain typographical errors, inaccuracies,
            or
            omissions. We reserve the
                              right to correct or make changes in such information without notice.</li>

          </ol>
        </p>


        <h3> 8. Proprietary Rights </h3>

        <p>
          As between Pretzly and you, Pretzly or its licensors own and reserve all right, title and
          interest
          in and to the Service
          and all hardware, software and other items used to provide the Service, other than the rights
          explicitly granted to you
          to use the Service in accordance with these Terms. No title to or ownership of any proprietary
          rights related to the
          Service is transferred to you pursuant to these Terms. All rights not explicitly granted to you
          are
          reserved by Pretzly.
                  </p>


        <h3>9.Intellectual Property Rights</h3>

        <p>
          <ol type="a">
            <li> Our names, graphics, videos, logos, page headers, button icons, scripts, and service
            names
            are our trademarks or
            trade dress in the United States and/or other countries (collectively the "Proprietary
            Marks"), and are owned by
            Pretzly. You may not use the Proprietary Marks without our prior written permission.
                          </li>

            <li> We make no proprietary claim to any third-party names, trademarks or service marks
            appearing on our Services. Any
            third-party names, trademarks, and service marks are property of their respective
            owners.
                          </li>
            <li> The information, content, documentation, guides, descriptions, advice, data, software
            and
            any other content viewable
            on, contained in, or downloadable from our Services (collectively, "Our Content"),
            including, without limitation, all
            audio files, text, graphics, charts, pictures, photographs, images, videos, line art,
            icons
            and renditions, are
                              copyrighted by us, or otherwise licensed to us or Our Content suppliers.</li>
            <li> We also own a copyright of a collective work in the selection, coordination,
            arrangement,
            presentation, display and
                              enhancement of Our Content (the "Collective Work").</li>
            <li> All software used on, or within our Services is our property or the property of our
            software vendors and is protected
            by United States and international copyright laws. Listening, viewing, reading,
            printing,
            downloading or otherwise using
            Our Content and/or the Collective Work does not entitle you to any ownership or
            intellectual
            property rights to Our
                              Content, the Collective Work, or the Software.</li>
            <li> You are solely responsible for any damages resulting from your infringement of our, or
            any
            third-parties,
            intellectual property rights regarding the Trademarks, Our Content, the Collective Work,
            the
            Software and/or any other
            harm incurred by us or our affiliates as a, direct or indirect, result of your copying,
            distributing, redistributing,
            transmitting, publishing or using the same for purposes that are contrary to the terms
            and
                              conditions of this Agreement.</li>

          </ol>
        </p>

        <h3> 10. Use of Our Content </h3>

        <p>
          <ol type="a">
            <li> We grant you a limited license to access, print, download or otherwise make personal
            use of
            Our Content and the
            Collective Work for your noncommercial personal use provided, however, that you shall
            not
            delete any proprietary notices
                              or materials with regard to the foregoing.</li>
            <li> You may not modify Our Content or the Collective Work or utilize them for any
            commercial
            purpose or any other public
            display, performance, sale, or rental, decompile, reverse engineer, or disassemble Our
            Content and the Collective Work,
                              or transfer Our Content or the Collective Work to another person or entity.</li>

          </ol>
        </p>



        <h3>11. User Content Rights and Related Responsibilities; License</h3>

        <p>
          <ol type="a">
            <li> "User Content" means, without limitation, any messages, texts, reviews, digital files,
            images, photos, personal
            profile (including your photo), artwork, videos, audio, comments, feedback, suggestions,
            reviews and documents, or any
            other content you upload, transmit or otherwise make available to Pretzly and its users
            via
            the Services. You represent
            and warrant that you own or otherwise control the rights to your User Content and agree
            to
            indemnify Pretzly and its
            affiliates for all claims arising from or in connection with any claims to any rights in
            your User Content or any
                              damages arising from your User Content.</li>
            <li> By submitting User Content on or through the Service, you grant Pretzly a worldwide,
            non-exclusive, royalty-free
            license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify,
            publish, transmit, display and
            distribute such User Content without attribution, and without the requirement of any
            permission from or payment to you
            or to any other person or entity, in any manner including, without limitation, for
            commercial, publicity, trade,
            promotional, or advertising purposes, and in any and all media now known or hereafter
            devised, and to prepare derivative
                              works of, or incorporate into other works, such User Content.</li>
            <li> In order for us to provide the Service to you, we require that you grant us certain
            rights
            with respect to User
            Content, including the ability to transmit, manipulate, process, store and copy User
            Content
            in order to provide our
            Services. Your acceptance of this Agreement gives us the permission to do so and grants
            us
            any such rights necessary to
                              provide the Service to you.</li>
            <li> You agree that any User Content you submit to our Service may be viewed by other users,
            any
            person visiting or
                              participating in the Service and by the public in general.</li>
            <li> Pretzly expressly disclaims any liability for the loss or damage to any User Content or
            any losses or damages you
            incur as a result of the loss or damage of any User Content. It is your responsibility
            to
            back-up any User Content to
                              prevent its loss.</li>
            <li> You are solely responsible for your User Content, including, without limitation,
            reviews,
            comments and feedback, and
                              any damages suffered by Pretzly resulting therefrom</li>
            <li> Pretzly may block, remove or return any User Content at any time for any reason
            whatsoever, or for no reason at all.
            We are not responsible for the authenticity, accuracy, completeness, appropriateness, or
                              legality of User Content.</li>
            <li> You represent and warrant that all information that you submit is authentic, accurate
            and
            truthful and that you will
            promptly update any information provided by you that subsequently becomes inaccurate,
                              misleading or false.</li>
            <li> User Content is not considered to be confidential. You agree not to submit User Content
            in which you have any
                              expectation of privacy.</li>
            <li> Pretzly has no control over User Content once posted, and it is possible that visitors
            to
            the Site or App may copy
                              User Content and repost it elsewhere.</li>
            <li> You agree not to post as part of the Service any offensive, inaccurate, incomplete,
            abusive, obscene, profane,
                              threatening, intimidating, harassing, racially offensive, or illegal material.</li>


          </ol>
        </p>


        <h3> 12. Community Guidelines </h3>

        <p>
          Pretzly is committed to providing a safe and friendly platform. To ensure the best possible
          experience for all users of
          our Services, we have established some basic guidelines and rules for participation. By using
          our
          Services, you agree
                      that you have read and will follow the rules and guidelines set forth below.</p>

        <p> Please take a moment to acquaint yourself with these important rules and guidelines. In order to
        preserve a climate that
        encourages productive, civil and meaningful interaction, Pretzly reserves the right to suspend
        or
        terminate a users
                      account for a violation of these rules.</p>

        <p> You agree not to use our Service including, without limitation any communication system we
        provide (
        i.e. chat areas,
        news groups, forums, communities, etc.) to transmit, distribute or post any User Content as
        defined
        in our Terms of
        Service that is offensive, inaccurate, incomplete, abusive, obscene, profane, threatening,
        intimidating, harassing,
        racially offensive, or illegal material. The following includes examples, without limitation, of
        the
        things you may not
                      do:</p>

      </p>
      <p>
        <ol type="a">
          <li> Promote material that exploits people in a sexual, pornographic or violent manner.</li>
          <li> Promote violence, racism, bigotry, hatred or physical harm of any kind against any group or
                          individual.</li>
          <li> Submit or post anything that exploits children or minors or that depicts cruelty to
                          animals.</li>
          <li> Stalk, harass, defame, abuse, bully, threaten or otherwise violate the legal rights of
                          others.</li>
          <li> Submit or post anything that depicts people in places where they have an expectation of
          privacy without their
          knowledge and consent, like someone’s home, a bathroom, dressing room, locker room, etc.
                      </li>
          <li> Impersonate any person or entity.g. Advocate for or harass or intimidate another person.
                      </li>
          <li> Advocate for or harass or intimidate another person.</li>
          <li> Promote information that is false or misleading.</li>
          <li> Promote illegal activities or conduct that is defamatory, libelous or otherwise
                          objectionable.</li>
          <li> Solicit personal information from anyone under the age of 18</li>
          <li> Use the service in an illegal manner or to commit an illegal act.</li>
          <li> Transmit any material that contains software viruses or any other computer code, files or
          programs designed to
                          interrupt, destroy or limit the functionality of any computer software or hardware.</li>
          <li> Provide instructional information about illegal activities.</li>
          <li> Infringe upon someone else's trademark, copyright or other intellectual property or other
                          rights.</li>
          <li> Promote commercial activities including without limitation sales, contests, sweepstakes,
          barter, advertising, and
                          business offers.</li>
          <li>Engage in SPAMM. Please don't transmit, distribute or post any advertising, promotional
          materials, junkmail, "spam,"
                          chain letters, pyramid schemes, or any other form of solicitation</li>
        </ol>
      </p>
      <p> Pretzly reserves the right to suspend or terminate any user account at any time for any reason, with
      or
      without warning
                  and without liability to the user.</p>

      <p>If you violate these guidelines, we may remove the offending content, suspend or terminate your
      account,
      and notify law
      enforcement. Please respect and honor these guidelines and report any violations to
      hello@pretzly.com to
      help us create
                  a better community.</p>

      <p> We have no obligation to delete content that you find personally objectionable or offensive.
                  We reserve the right to modify these rules with or without notice at any time.</p>

      <p>If you have questions, comments, concerns or feedback regarding these guidelines or our Services,
      please
      contact us at
                  hello@pretzly.com.</p>

      <h3> 13. User Comments and Postings</h3>

      <p>
        Pretzly does not investigate any posted user comments, contributions, responses, suggestions or
        feedback for accuracy or
        truth. Pretzly has no editorial control over or responsibility for such content and any opinions,
        statements or
        contributions made by a user are those of the user and not Pretzly. We do not endorse any opinion,
        statement or
        contribution provided by any user. Users may be held legally responsible for damages suffered by
        other users or third
        parties as a result of their comments if they are legally actionable or defamatory, or infringe on a
        another parties
        intellectual property rights. You agree that Pretzly is not legally responsible for any comments
        posted or made
        available on our Services by any users or third parties, even if that information is defamatory or
        otherwise legally
        actionable. Pretzly reserves the right to remove user comments or information that, in our sole
        judgment, violates these
        Terms or negatively affects our Services.
              </p>


      <h3> 14. Third Party Links, Services and Content </h3>

      <p>
        The Service may contain features, services and functionalities linking you to, or providing you with
        access to third
        party services and content, websites, directories, servers, networks, systems, information,
        databases, applications,
        software, programs, courses , services, and the Internet as a whole. Because we have no control over
        such sites and
        resources, we are not responsible for the availability of such external sites or resources, and do
        not endorse and are
        not responsible or liable for any content, advertising, courses or other materials on or available
        from such sites or
        resources. When you visit or use a third party’s website you agree to read and consent to the third
        party’s Terms of
                  Service and Privacy Policy and you release us from any liability.</p>


      <h3>15. Software</h3>

      <p>
        <ol type="a">
          <li>a. If you receive software from us, its use is governed in one of two ways: If you're
          presented with license terms that
          you must accept in order to use the software, those terms apply; if no license is presented
          to you, these Terms apply.
                          We reserve all other rights to the software</li>
          <li>b. Any license is for the sole purpose of enabling you to use and enjoy the benefit of the
          Services as provided by
          Pretzly, in the manner permitted by these terms. You may not copy, modify, distribute, sell,
          or lease any part of our
          Services or included software, nor may you reverse engineer or attempt to extract the source
                          code of that software.</li>
          <li>c. We may automatically check your version of the software. We may also automatically
          download to your device or
                          computer new versions of the software.</li>
          <li>d. Any software is licensed, not sold. Unless we notify you otherwise, the software license
          ends when your Service ends.
          You must then promptly uninstall the software, or we may disable it. You must not work
          around any technical limitations
                          in the software.</li>
          <li>e. The software is subject to applicable U.S. export laws and regulations. You must comply
          with all domestic and
          international export laws and regulations that apply to the software. These laws include
          restrictions on destinations,
          end users, and end use. Without limitation, you may not transfer the software or Service
          without U.S. government
          permission to anyone on U.S. government exclusion lists. You represent and warrant that
          you're not on any of those lists
                          or under the control of, or an agent for, anyone on those lists.</li>
        </ol>
      </p>


      <h3> 16. Mobile Application from a Third Party App Store </h3>

      <p>
        The following applies to any App Store Sourced Application accessed through or downloaded from a
        Third Party App Store:
              </p>
      <p>
        <ol type="a">
          <li> You acknowledge and agree that (i) the Terms are concluded between you and Pretzly only,
          and not the Third Party App
          Store, and (ii) Pretzly, not the Third Party App Store, is solely responsible for the App
          Store Sourced Application and
          content thereof. Your use of the App Store Sourced Application must comply with the Third
          Party App Store Terms of
                          Service.</li>
          <li> You acknowledge that the Third Party App Store has no obligation whatsoever to furnish
          any maintenance and support
                          services with respect to the App Store Sourced Application.</li>
          <li> In the event of any failure of the App Store Sourced Application to conform to any
          applicable warranty, you may
          notify the Third Party App Store, and the Third Party App Store will refund the purchase
          price for the App Store Sourced
          Application to you, and to the maximum extent permitted by applicable law, the Third Party
          App Store will have no other
          warranty obligation whatsoever with respect to the App Store Sourced Application. As between
          Pretzly and the Third Party
          App Store, any other claims, losses, liabilities, damages, costs or expenses attributable to
          any failure to conform to
                          any warranty will be the sole responsibility of Pretzly.</li>
          <li> You and Pretzly acknowledge that, as between Pretzly and the Third Party App Store, the
          Third Party App Store is not
          responsible for addressing any claims you have or any claims of any third party relating to
          the App Store Sourced
          Application or your possession and use of the App Store Sourced Application, including, but
          not limited to: (i) product
          liability claims; (ii) any claim that the App Store Sourced Application fails to conform to
          any applicable legal or
          regulatory requirement; and (iii) claims arising under consumer protection or similar
                          legislation.</li>
          <li> You and Pretzly acknowledge that, in the event of any third-party claim that the App Store
          Sourced Application or
          your possession and use of that App Store Sourced Application infringes that third party’s
          intellectual property rights,
          as between Pretzly and the Third Party App Store, Pretzly, not the Third Party App Store,
          will be solely responsible for
          the investigation, defense, settlement and discharge of any such intellectual property
          infringement claim to the extent
                          required by the Terms.</li>
          <li> You and Pretzly acknowledge and agree that the Third Party App Store, and their
          subsidiaries, are third-party
          beneficiaries of the Terms as related to your license of the App Store Sourced Application,
          and that, upon your
          acceptance of the terms and conditions of the Terms, the Third Party App Store will have the
          right (and will be deemed
          to have accepted the right) to enforce the Terms as related to your license of the App Store
          Sourced Application against
                          you as a third-party beneficiary thereof</li>
          <li> Without limiting any other terms of the Terms, you must comply with all applicable
          third-party terms of agreement
                          when using the Third Party App Store Sourced Application.</li>
        </ol>
      </p>


      <h3>17. Interruption of Service</h3>

      <p>
        <ol type="a">
          <li> Your access and use of our Services may be interrupted from time to time for any of several
          reasons, including,
          without limitation, the malfunction of equipment, periodic updating, maintenance or repair
          of our Services or other
                          actions that we, in our sole discretion, may elect to take.</li>
          <li>You agree that we will not be liable to you or to any third party for any interruption of
          the Services or any part
                          thereof.</li>
        </ol>
      </p>

      <h3> 18. Electronic Communications </h3>

      <p>
        <ol type="a">
          <li> Although we may choose to communicate with you by regular mail, we may also choose to
          communicate with you by
          electronic means including, without limitation, email, telephone, text, SMS or by posting
          notices on our Services. When
                          you use our Services, you consent to communicating with us electronically.</li>
          <li> You agree that all agreements, notices, disclosures and other communications that we
          provide to you electronically
                          satisfy any legal requirement that such communications be in writing.</li>
        </ol>
      </p>
      <h3>19. Electronic Transactions</h3>

      <p>
        <ol type="a">
          <li>Your use of the Services includes the ability to enter into agreements, including these
          Terms, and to make
          transactions electronically, including potential financial transactions and purchases. You
          acknowledge that your
          electronic submissions constitute your agreement and intent to be bound by such agreements,
          financial transactions and
                          purchases.</li>
          <li> Your agreement and intent to be bound by electronic submissions applies to all records
          relating to all transactions
          you enter into on this site, including purchases, financial transactions, notices of
          cancellation, policies, contracts,
                          and applications.</li>
        </ol>
      </p>
      <h3>20. Third Party Social Networking</h3>

      <p>
        If you access our Services through a third party social networking site or application including,
        but not limited to,
        Facebook, LinkedIn or Twitter, you authorize Pretzly to access certain information about you that is
        made available
        through that third party social networking site and further authorize us to collect, store, retain
        and use, your
        information in accordance with our Privacy Policy.
      </p>
    <h3>21. Privacy and Your Personal Information</h3>

    <p>
      For information about the Pretzly data protection practices and privacy policies, please read our
      Privacy Policy where
                  you accessed these Terms of Service or here <a
        href="privacy">(https://pretzly.com/privacy).</a>
                  This
                  policy explains how
                  we treat your
                  personal information, and protect your privacy when you use the Services. You agree to the use of
                  your data in
                  accordance with Pretzly Privacy Policy.

              </p>
    <h3>22. Copyright and Intellectual Property Policy</h3>

    <p>
      We respect the intellectual property rights of others. We reserve the right to remove any User
      Content on the Services
      which allegedly infringe upon another person's copyright, trademark or other intellectual property
      right, and/or
      terminate, discontinue, suspend and/or restrict the account or ability to visit and/or use the
      Services or remove, edit,
      or disable any User Content on the Services which allegedly infringe upon another person's
      intellectual property rights.
      We provide this policy pursuant to Section 512 of the Copyright Revision Act, as enacted through the
      Digital Millennium
                  Copyright Act ("DMCA").</p>
    <p> A valid complaint under the DMCA must provide the following information in writing:</p>
    <p>
      <ol type="a">
        <li> An electronic or physical signature of a person authorized to act on behalf of the
                          copyright owner.</li>
        <li> Identification of the copyrighted work that you claim has been infringed.</li>
        <li> Identification of the material that is claimed to be infringing and where it is located
                          on the Service.</li>
        <li> Information reasonably sufficient to permit us to contact you, such as your address,
        telephone number, and, email
                          address.</li>
        <li> A statement that you have a good faith belief that use of the material in the manner
        complained of is not authorized
                          by the copyright owner, its agent, or law.</li>
        <li> A statement, made under penalty of perjury, that the above information is accurate, and
        that you are the copyright
                          owner or are authorized to act on behalf of the owner.</li>
      </ol>
    </p>
    <p>Federal law provides that if you knowingly misrepresent that online material is infringing, you may
    be subject to
    criminal prosecution for perjury and civil penalties, including monetary damages, court costs, and
    attorneys' fees. The
    above information must be submitted to the Copyright Agent at: hello@pretzly.com
              </p>
    <h3>Counter-Notice</h3>
    <p>If you receive notification that your User Content has been removed from the Services, you have an
    opportunity to appeal
    this removal if you believe that your User Content was removed in error. If you believe that the
    removed User Content
    does not violate any third-party rights, or you have authorization from the rights-holder or the
    rights-holder’s
    authorized agent to use the material in question, you may appeal the removal by sending an email
    notice to the Copyright
                  Agent at: hello@pretzly.com with the following information:</p>
    <p>
      <ol type="a">
        <li> Your physical or electronic signature.</li>
        <li> Identification of the material that has been removed or to which access has been disabled,
        and
        the location at which
                          the material appeared before it was removed or access to it was disabled.</li>
        <li> A statement under penalty of perjury that you have a good-faith belief that the material
        was
        removed or disabled as a
                          result of mistake or misidentification of the material to be removed or disabled.</li>
      </ol>
    </p>
    <p>Your name, address, and telephone number, and a signed statement that you consent to the jurisdiction
    of state and
    federal courts in the state of California, and that you will accept service of process from the
    party who made the
                  initial infringement claim (or their authorized agent) if they choose to pursue legal action.</p>
    <h3>23. Disclaimers; No Warranties</h3>
    <p>
      <ol type="a">
        <li><b> ALL SERVICES AND PRODUCTS AVAILABLE FROM PRETZLY ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS. TO THE FULL
        EXTENT PERMISSIBLE BY APPLICABLE LAW. PRETZLY AND ITS PARENTS, SUBSIDIARIES, PARTNERS,
        AFFILIATES, OFFICERS, DIRECTORS,
        EMPLOYEES AND AGENTS, (COLLECTIVELY, THE “PRETZLY PARTIES”) DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING, BUT
        NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                              PURPOSE AND NON-INFRINGEMENT.</b></li>
        <li><b> WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES OR PRODUCTS
        WILL MEET YOUR REQUIREMENTS,
        (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR (III) THE
        QUALITY OF ANY SERVICES OR
                              INFORMATION OBTAINED BY YOU FROM OR THOUGH US WILL MEET YOUR EXPECTATIONS.</b></li>
        <li><b>THE SERVICES CAN INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL
        ERRORS. FURTHERMORE, THE
        INFORMATION OR SERVICES ON THIS SITE OR APP MAY BE OUT OF DATE. WE MAY MAKE CHANGES TO
        THE SERVICES, PRODUCTS AND
        INFORMATION ON THIS SITE OR APP AT ANY TIME WITHOUT NOTICE, HOWEVER WE HAVE NO
                              OBLIGATION TO DO SO.</b></li>
        <li><b> THE PRETZLY PARTIES DO NOT WARRANT THAT THE SERVICES OR THE SERVERS THAT MAKE THE
        SERVICE AVAILABLE WILL BE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY PRODUCT DESCRIPTION OR OTHER CONTENT
        OFFERED AS PART OF THE SERVICES,
                              ARE ACCURATE, RELIABLE, CURRENT OR COMPLETE.</b></li>
        <li><b> YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. IF YOU DOWNLOAD
        ANY CONTENT FROM THE SERVICE,
        YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
        TO YOUR COMPUTER SYSTEM OR LOSS
                              OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT THROUGH THE SERVICE.</b></li>
        <li><b> YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. IF YOU DOWNLOAD
        ANY CONTENT FROM THE SERVICE,
        YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
        TO YOUR COMPUTER SYSTEM OR LOSS
                              OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT THROUGH THE SERVICE.</b></li>
        <li><b> SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
        SO SOME PARTS OF THE ABOVE
                              EXCLUSIONS MAY NOT APPLY TO YOU.</b></li>
      </ol>
    </p>
    <h3>24. LIMITATION OF LIABILITY</h3>
    <p>
      <ol type="a">
        <li><b> IN NO EVENT SHALL WE BE RESPONSIBLE TO, OR LIABLE TO YOU, OR ANY THIRD PARTY, WHETHER
        IN CONTRACT, WARRANTY, TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, FOR ANY DAMAGES INCLUDING, BUT NOT LIMITED TO,
        SPECIAL, INCIDENTAL, INDIRECT OR
        CONSEQUENTIAL DAMAGES THAT INCLUDE, BUT ARE NOT LIMITED TO, DAMAGES FOR ANY PERSONAL
        INJURY TO YOU OR OTHERS, OR LOSS OF
        PROFIT, REVENUE OR BUSINESS, AS A DIRECT OR INDIRECT RESULT OF: (I) YOUR ACCESS AND USE
        OF OUR SERVICES; (II) YOUR
        BREACH OR VIOLATION OF THE TERMS AND CONDITIONS OF THIS AGREEMENT; (III) YOUR DELAY IN
        ACCESSING OR INABILITY TO ACCESS
        OR USE OUR SERVICES FOR ANY REASON; (IV) YOUR DOWNLOADING OF ANY OF OUR CONTENT OR THE
        COLLECTIVE WORK FOR YOUR USE; OR
        (V) YOUR RELIANCE UPON OR USE OF OUR CONTENT OR THE COLLECTIVE WORK, WHETHER RESULTING
        IN WHOLE OR IN PART, FROM BREACH
        OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF WE
        AND/OR OUR SUPPLIERS HAD BEEN
                              ADVISED OF THE POSSIBILITY OF DAMAGES.</b></li>
        <li><b> THIS LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER
        SERVICES OR PRODUCTS RECEIVED
        THROUGH OR ADVERTISED IN OUR SERVICES OR RECEIVED THROUGH ANY LINKS PROVIDED IN OUR
                              SERVICES.</b></li>
        <li><b> WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES SHALL WE BE HELD LIABLE FOR ANY
        DELAY OR FAILURE IN
        PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ACTS OF NATURE, FORCES, OR CAUSES
        BEYOND THEIR REASONABLE CONTROL,
        INCLUDING, WITHOUT LIMITATION, INTERNET FAILURES, COMPUTER EQUIPMENT FAILURES,
        TELECOMMUNICATION EQUIPMENT FAILURES,
        OTHER EQUIPMENT FAILURES, ELECTRICAL POWER FAILURES, STRIKES, LABOR DISPUTES, RIOTS,
        INSURRECTIONS, CIVIL DISTURBANCES,
        SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS, STORMS, EXPLOSIONS, ACTS OF GOD, WAR,
        GOVERNMENTAL ACTIONS, ORDERS OF
                              DOMESTIC OR FOREIGN COURTS OR TRIBUNALS OR NON-PERFORMANCE OF THIRD PARTIES.</b></li>
        <li><b> OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
        INDEPENDENT CONTRACTORS,
        SHAREHOLDERS, REPRESENTATIVES, AND AGENTS ARISING OUT OF THIS AGREEMENT SHALL NOT EXCEED
        THE AMOUNT PAID, IF ANY, BY YOU
        TO PRETZLY DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF THE
        INCIDENT THAT FIRST GAVE RISE TO A
                              CLAIM, LAWSUIT, ARBITRATION OR ANY OTHER LEGAL PROCEEDING AGAINST PRETZLY.</b></li>
        <li><b> YOU AND WE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO OUR SERVICES
        MUST COMMENCE WITHIN ONE (1) YEAR
        AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                              BARRED.</b></li>
      </ol>
    </p>
    <h3>25. Interaction With Others</h3>
    <p>
      <ol type="a">
        <li><b> WHEN USING OUR SERVICE, ALWAYS USE CAUTION WHEN GIVING OUT ANY PERSONALLY IDENTIFYING
        INFORMATION ABOUT YOURSELF.YOU
        ARE SOLELY RESPONSIBLE FOR DETERMINING THE IDENTITY, SUITABILITY AND FOR YOUR
        INTERACTIONS WITH THE PEOPLE YOU CHOOSE TO
        ENGAGE WITH THROUGH THE SERVICE ONLINE OR IN PERSON. YOU UNDERSTAND THAT WE CURRENTLY DO
        NOT CONDUCT ANY BACKGROUND
        CHECKS INCLUDING, BUT NOT LIMITED TO, CRIMINAL, FINANCIAL, SEX OFFENDER OR ANY OTHER
        BACKGROUND CHECKS OR SCREENINGS. WE
        MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF THE PEOPLE YOU CHOOSE TO
        INTERACT WITH ONLINE OR IN PERSON.
        WHILE NOT OBLIGATED TO DO SO, PRETZLY RESERVES THE RIGHT AT ITS SOLE DISCRETION TO
        CONDUCT ANY CRIMINAL OR OTHER
                              BACKGROUND CHECK OR SCREENINGS AT ANY TIME AND USING AVAILABLE PUBLIC RECORDS.</b></li>
        <li><b> YOU ASSUME ALL RISK WHEN USING THE SERVICES, INCLUDING, WITHOUT LIMITATION, ALL RISKS
        ASSOCIATED WITH ANY, ONLINE,
                              OFFLINE AND IN PERSON, PERSONAL INTERACTIONS WITH OTHERS.</b></li>
        <li><b>IN NO EVENT SHALL PRETZLY, ITS EMPLOYEES, CONSULTANTS, EXECUTIVES OR OFFICERS BE LIABLE
        (DIRECTLY OR INDIRECTLY) FOR
        ANY LOSSES OR DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL,
        COMPENSATORY, CONSEQUENTIAL, AND/OR
        INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF A USER OR ANYONE ELSE IN
        CONNECTION WITH THE USE OF THE SERVICE
        INCLUDING, WITHOUT LIMITATION, THEFT, BODILY INJURY, EMOTIONAL DISTRESS, DEATH AND/OR
        ANY OTHER DAMAGES RESULTING FROM
                              COMMUNICATIONS WITH, CONTACT WITH OR PERSONAL INTERACTION WITH OTHER USERS.</b></li>
      </ol>
    </p>
    <h3>26. Indemnity</h3>
    <p>
      <ol type="a">
        <li> You agree that you will be personally responsible for your use of the Service and any
        products you may purchase from
        us or through our Service; and you further agree to defend, indemnify and hold harmless
        Pretzly and its officers,
        directors, employees, consultants, affiliates, subsidiaries and agents from and against any
        and all claims, liabilities,
        damages, losses and expenses, including reasonable attorneys’ and accounting fees and costs,
        arising out of or in any
        way connected with (i) your access to, use of or alleged use of the Service or the products
        you purchase through the
        Service; (ii) your violation of these Terms of Service or any representation, warranty, or
        agreements referenced herein,
        or any applicable law or regulation; (iii) your infringement upon the property rights,
        intellectual property rights
        (copyrights and trademarks) or other rights of others; (iv) your violation of any third
        party right, including without
        limitation publicity, confidentiality, or privacy right; (v) any death, or serious physical
        or serious emotional harm,
                          to you or any third party resulting from your use of the Service.</li>
        <li> We reserve the right, at our own expense, to assume the exclusive defense and control of
        any matter otherwise subject
        to indemnification by you, and in such case, you agree to cooperate with our defense of such
                          claim.</li>
      </ol>
    </p>
    <h3>27. Release</h3>
    <p>
      <ol type="a">
        <li> By using the Services, you release, to the maximum extent allowed by law, Pretzly, its
        officers, directors,
        employees, affiliates, and agents from claims, demands and damages of every kind and nature,
        known and unknown,
        suspected and unsuspected, disclosed and undisclosed, arising out of, or in any way
        connected with, your use of our
        Services or products, including without limitation, any death or serious emotional or
                          serious physical harm.</li>
        <li><b>b. If you are a California resident, you waive California Civil Code §1542, which says:
        “A general release does not
        extend to claims which the creditor does not know or suspect to exist in his favor at
        the time of executing the release,
                              which if known by him must have materially affected his settlement with the debtor.”</b>
        </li>
      </ol>
    </p>
    <h3>28. Governing Law</h3>
    <p>This Agreement, and any separate agreements whereby we provide you Services, shall be governed by and
    construed in
    accordance with the laws of the State of California, without regard to its conflicts of laws
    principles. The parties
    hereby consent to the exclusive jurisdiction and venue in the state and federal courts in the State
    of California in and
                  for the County in which Pretzly has established its principal office.</p>
    <h3>29. Our Remedies</h3>
    <p>
      <ol type="a">
        <li>a. You acknowledge that we may be irreparably damaged if this Agreement is not specifically
        enforced, and damages at law
        would be an inadequate remedy. Therefore, in the event of a breach or threatened breach of
        any provision of this
        Agreement by you, we shall be entitled, in addition to all rights and remedies, to an
        injunction restraining such breach
        or threatened breach, without being required to show any actual damage or to post an
        injunction bond, and/or to a decree
                          for specific performance of the provisions of this Agreement.</li>
        <li>b. For purposes of this Section, you agree that any action or proceeding with regard to such
        injunction restraining such
        breach or threatened breach shall be brought in the state courts of record or a United
        States District Court for the
        State of California in and for the County in which Pretzly has established its principal
        office. You consent to the
        jurisdiction of such court and waive any objection to the venue of any such action or
                          proceeding in such court.</li>
      </ol>
    </p>
    <h3>30. Dispute Resolution</h3>
    <p><b>THIS AGREEMENT CONTAINS AN AGREEMENT TO ARBITRATE, WHICH WILL, WITH LIMITED EXCEPTION, REQUIRE YOU
    TO SUBMIT CLAIMS YOU
    HAVE AGAINST US TO BINDING AND FINAL ARBITRATION. THIS AGREEMENT ALSO CONTAINS AN AGREEMENT THAT
    YOU WILL ONLY BE
    PERMITTED TO PURSUE CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
    IN ANY CLASS OR
    REPRESENTATIVE ACTION OR PROCEEDING. PLEASE READ THE FOLLOWING TERMS CAREFULLY BEFORE ACCESSING
    OR USING ANY OF THE
    SERVICE. EACH TIME YOU ACCESS OR USE THE SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU
    DO NOT AGREE TO BE BOUND
    BY THE MANDATORY ARBITRATION PROVISION AND THE CLASS ACTION WAIVER YOU MAY NOT ACCESS OR USE THE
                      SERVICE.</b></p>

    <p> For any dispute you have with Pretzly, you agree to first contact us at hello@pretzly.com and
    attempt to resolve the
    dispute with us informally. If Pretzly has not been able to resolve the dispute with you informally,
    we each agree to
    resolve any claim, dispute, or controversy as follows:
              </p>
    <p>
      <ol type="a">
        <li><b> Claims.</b> You and we agree that any claim or dispute at law or equity between us
                          relating in any way to or arising out
                          of this or previous versions of this Agreement, your use of or access to the Services will
                          be resolved in accordance
                          with the provisions set forth in this Legal Disputes section. Please read this section
                          carefully. It affects your rights
                          and will have a substantial impact on how claims you and we have against each other are
                          resolved.</li>
        <li><b> Applicable Law.</b> You agree that the laws of the State of California, without regard
                          to
                          principles of conflict of laws,
                          will govern this Agreement and any claim or dispute that has arisen or may arise between you
                          and us, except as otherwise
                          stated in this Agreement.</li>
        <li><b> Agreement to Arbitrate.</b> You and we each agree that any and all disputes or claims
                          between
                          you and us relating in any
                          way to or arising out of this or previous versions of this Agreement, your use of or access
                          to our Services, or any
                          courses or services delivered, sold, offered, or purchased through our Services shall be
                          resolved exclusively through
                          final and binding arbitration, rather than in court, except that you may assert claims in
                          small claims court, if your
                          claims qualify.</li>
        <li><b> Prohibition of Class and Representative Actions and Non-Individualized Relief. YOU AND
        WE AGREE THAT EACH OF US MAY
        BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
        CLASS MEMBER IN ANY PURPORTED CLASS
        OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE
        ARBITRATOR MAY NOT CONSOLIDATE OR
        JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY
        FORM OF A CONSOLIDATED,
        REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
        MONETARY, INJUNCTIVE, AND
        DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE
        EXTENT NECESSARY TO PROVIDE
        RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT
                              AFFECT OUR OTHER USERS.</b></li>
        <li><b> Arbitration Procedures.</b> Arbitration is more informal than a lawsuit in court. There
                          is no
                          judge or jury in
                          arbitration, and court review of an arbitration award is very limited. However, an
                          arbitrator can award the same damages
                          and relief on an individual basis that a court can award to an individual. An arbitrator
                          should apply the terms of this
                          Agreement as a court would. The arbitrator, and not any federal, state, or local court or
                          agency, shall have exclusive
                          authority to resolve any dispute arising out of or relating to the interpretation,
                          applicability, enforceability or
                          formation of this Agreement to Arbitrate, any part of it, or of this Agreement including,
                          but not limited to, any claim
                          that all or any part of the Agreement to Arbitrate or this Agreement is void or voidable.
                          The arbitration will be
                          conducted by the American Arbitration Association ("AAA") under its rules and procedures,
                          including the AAA's
                          Supplementary Procedures for Consumer-Related Disputes (as applicable), as modified by this
                          Agreement to Arbitrate. The
                          arbitrator will decide the substance of all claims in accordance with the laws of the State
                          of California, including
                          recognized principles of equity, and will honor all claims of privilege recognized by law.
                          The arbitrator shall not be
                          bound by rulings in prior arbitrations involving our other users, but is bound by rulings in
                          prior arbitrations
                          involving the same user to the extent required by applicable law. The arbitrator's award
                          shall be final and binding and
                          judgment on the award rendered by the arbitrator may be entered in any court having
                          jurisdiction thereof.</li>
        <li><b> Judicial Forum for Legal Disputes.</b> Unless you and we agree otherwise, in the event
                          that
                          the Agreement to Arbitrate
                          above is found not to apply to you or to a particular claim or dispute as a result of a
                          decision by the arbitrator or a
                          court order, you agree that any claim or dispute that has arisen or may arise between you
                          and us must be resolved
                          exclusively by a state or federal court located in the State of California in and for the
                          County in which Pretzly has
                          established its principal office. You and we agree to submit to the personal jurisdiction of
                          the courts located within
                          California for the purpose of litigating all such claims or disputes. You also agree that:
                          (i) our Services shall be
                          deemed solely based in the State of California; and (ii) our Services shall be deemed
                          passive Services that do not give
                          rise to personal jurisdiction over us and our assigns, either specific or general, in
                          jurisdictions other than the State
                          of California.</li>
      </ol>
    </p>
    <h3>31. Security</h3>
    <p>Violating the security of our Services is prohibited and may result in criminal and civil liability.
    Pretzly may
    investigate incidents involving such violations and may involve, and will cooperate with law,
    enforcement if a criminal
    violation is suspected. Security violations include, without limitation, unauthorized access to or
    use of data or
    systems including any attempt to probe, scan, or test the vulnerability of the Service or to breach
    security or
    authentication measures, unauthorized monitoring of data or traffic and interference with service to
    any user, host, or
    network.
              </p>
    <h3>32. Law Enforcement</h3>
    <p>
      <ol type="a">
        <li> Pretzly is committed to cooperating with law enforcement while respecting each individual’s
        right to privacy. If
        Pretzly receives a request for user account information from a government agency
        investigating criminal activity, we
        will review the request to be certain that it satisfies all legal requirements before
        releasing information to the
                          requesting agency.</li>
        <li>b. Furthermore, under 18 U.S.C. §§ 2702(b)(8) and 2702(c)(4) (Voluntary Disclosure Of
        Customer Communications or
        Records), Pretzly may disclose user account information to law enforcement, without a
        subpoena, court order, or search
        warrant, in response to a valid emergency when we believe that doing so is necessary to
        prevent death or serious
        physical harm to someone. Pretzly will not release more information than it prudently
        believes is necessary to prevent
                          harm in an emergency situation.</li>
      </ol>
    </p>
    <h3>33. Statutory Rights; Notice To California Residents</h3>
    <p>If you are a California resident, under California Civil Code Section 1789.3, you may contact the
    Complaint Assistance
    Unit of the Division of Consumer Services of the California Department of Consumer Affairs in
    writing, currently at:
    1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone, currently at (800)
    952-5210 in order
    to resolve a complaint regarding our Site or to receive further information regarding use of our
                  Site.</p>

    <h3>34. Amendments to this Agreement</h3>
    <p>We reserve the right to update, amend and/or change this Agreement at any time in our sole discretion
    and without
    notice. Updates to this Agreement will be posted here. Amendments will take effect immediately upon
    us posting the
    updated Agreement on our Services. You are encouraged to revisit this Agreement from time to time in
    order to review any
    changes that have been made. The date on which this Agreement was last updated will be noted
    immediately below this
    Agreement. Your continued access and use of our Services following the posting of any such changes
    shall automatically
                  be deemed your acceptance of all changes.</p>
    <h3>35. Severability</h3>
    <p>If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator or court
    of competent
    jurisdiction, this Agreement as a whole shall not be deemed unlawful, void or unenforceable, but
    only that portion of
                  this Agreement that is unlawful, void or unenforceable shall be stricken from this Agreement.</p>
    <h3>36. No Waiver</h3>
    <p>Our failure to enforce any right or provision in these Terms shall not constitute a waiver of such
    right or provision
                  unless acknowledged and agreed to by us in writing.</p>
    <h3>37. Entire Understanding</h3>
    <p>This Agreement and the Privacy Policy represent the entire understanding and agreement between you
    and us regarding the
    subject matter of the same, and supersede all other previous agreements, understandings and/or
    representations regarding
                  the same.</p>
    <p>If you have questions, comments, concerns or feedback regarding this Agreement or our Services,
    please contact us at
                  hello@pretzly.com</p>
    <p><b>Last updated: January 04, 2021</b></p>
  </div>
)

export default TermsContent;
