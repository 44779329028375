import React from 'react';

const PrivacyContent = () => {
  return (
    <div style={{ color: 'RGB(172, 192, 247)' }}>
      <p>We at Pretzly Inc respect the privacy of your personal information and, as such, make every effort to
      ensure your
      information is protected and remains private. As the owner and operator of www.pretzly.com (the
      "Website") and the
      mobile application Pretzly (the "App"), hereafter referred to in this Privacy Policy as "Pretzly",
      "us", "our" or "we",
      we have provided this Privacy Policy to explain how we collect, use, share and protect information
      about the users of
      our Website and mobile App (hereafter referred to as “user”, “you” or "your"). For the purposes of
      this Agreement and
      our Terms of Service, any use of the terms "Pretzly", "us", "our" or "we" includes Pretzly Inc,
      without limitation. We
      will not use or share your personal information with anyone except as described in this Privacy
                Policy.</p>
      <p>This Privacy Policy will inform you about the types of personal data we collect, the purposes for
      which we use the data,
      the ways in which the data is handled and your rights with regard to your personal data.
      Furthermore, this Privacy
      Policy is intended to satisfy the obligation of transparency under the EU General Data Protection
      Regulation 2016/679
                ("GDPR") and the laws implementing the GDPR.</p>
      <p>For the purpose of this Privacy Policy the Data Controller of personal data is Pretzly Inc and our
      contact details are
      set out in the Contact section at the end of this Privacy Policy. Data Controller means the natural
      or legal person who
      (either alone or jointly or in common with other persons) determines the purposes for which and the
      manner in which any
                personal information are, or are to be, processed.</p>
      <p>For purposes of this Privacy Policy, "Your Information" or "Personal Data" means information about
      you, which may be of
      a confidential or sensitive nature and may include personally identifiable information ("PII")
      and/or financial
      information. PII means individually identifiable information that would allow us to determine the
      actual identity of a
      specific living person, while sensitive data may include information, comments, content and other
      information that you
                voluntarily provide.</p>
      <p>Pretzly collects information about you when you use our Website or App to access our services, and
      other online products
      and services (collectively, the “Services”) and through other interactions and communications you
      have with us. The term
      Services includes, collectively, various applications, websites, widgets, email notifications and
      other mediums, or
                portions of such mediums, through which you have accessed this Privacy Policy.</p>
      <p>We may change this Privacy Policy from time to time. If we decide to change this Privacy Policy, we
      will inform you by
      sending you a notice to your registered email address and by posting the revised Privacy Policy on
      the Site and in the
      App. If the changes to the Privacy Policy are significant, the email notice we send you will require
      you to accept the
      revised Privacy Policy terms. Those changes will go into effect on the "Last updated" date shown at
      the end of this
      Privacy Policy. By continuing to use the Site or Services, you consent to the revised Privacy
      Policy. We encourage you
                to periodically review the Privacy Policy for the latest information on our privacy practices.</p>
      <p><b>BY CHECKING THE BOX ACKNOWLEDGING THAT YOU HAVE READ AND AGREE TO THIS PRIVACY POLICY AND
      PROVIDING EXPLICIT CONSENT AT
      THE TIME OF YOUR REGISTRATION FOR YOUR ACCOUNT, AND BY ACCESSING OR USING OUR SERVICES, YOU
      CONSENT TO THE COLLECTION,
      TRANSFER, MANIPULATION, STORAGE, DISCLOSURE AND OTHER USES OF YOUR INFORMATION (COLLECTIVELY,
      "USE OF YOUR INFORMATION")
      AS DESCRIBED IN THIS PRIVACY POLICY AND OUR TERMS OF SERVICE WHICH MAY BE ACCESSED HERE
                    (<a href="terms">https://pretzly.com/terms.</a>) IF
                    YOU DO NOT, OR NO LONGER, AGREE WITH OR CONSENT TO THIS PRIVACY POLICY AND OUR TERMS OF SERVICE
                    YOU MAY NOT ACCESS OR
                    USE OUR SERVICES. FURTHERMORE, FOR USERS OF OUR SERVICES PROVIDING INFORMATION FROM THE EUROPEAN
                    UNION, WE RELY UPON THE
                    LEGAL GROUNDS FOUND IN THE SECTION ENTITLED "THE LEGAL BASIS AND REASONS FOR COLLECTION AND
                    PROCESSING YOUR PERSONAL
                    INFORMATION" FOUND HEREINAFTER IN THIS PRIVACY POLICY.</b></p>

      <h3>1. Information Provided Directly By You</h3>
      <p>We collect information you provide directly to us, such as when you request information, create or
      modify your personal
      account, request Services, order Services, complete a Pretzly form, survey, quiz or application,
      contact customer
      support, join or enroll for an event or otherwise communicate with us in any manner. This
      information may include,
      without limitation: name, date of birth, e-mail address, physical address, phone number, photograph,
      credit card or
                other method of payment information, or any other personal information you choose to provide.</p>
      <h3>2. Information Collected Through Your Use of Our Services</h3>
      <p>We collect information about your activity on our services, for instance how you use them (e.g., date
      and time you
      logged in, features you’ve been using, searches, clicks and pages which have been shown to you,
      referring webpage
      address, advertising that you click on) and how you interact with other users and us.The following
      are situations in
                which you may provide Your Information to us:</p>
      <ol type="a">
        <li>When you fill out forms or fields through our Services;</li>
        <li> When you register for an account with our Service;</li>
        <li> When you create your profile, including without limitation, details on your gender, race,
        sexual orientation,
                    religious beliefs interests, personality, lifestyle, and other details about you;</li>
        <li> When you provide responses to a survey;</li>
        <li>When you provide responses to a survey;</li>
        <li> When you communicate with another user through our Services;</li>
        <li> When you answer questions on a quiz;</li>
        <li> When you join or enroll in an event through our Services;</li>
        <li> When you order services or products from, or through our Service;</li>
        <li> When you sign up for any newsletters or other materials through our Services;</li>
        <li> When you provide information to us through a third-party application, service or Website;
                </li>
        <li> When you communicate with us or request information about us or our products or Services,
        whether via email or other
                    means;</li>
        <li> When you participate in any of our marketing initiatives, including, contests, events, or
                    promotions; and</li>
        <li> When you participate in forums, chat sessions, reviews or provide or post user generated
        content or other
                    submissions.</li>
      </ol>
      <p>We also automatically collect information via the Website or App through the use of various
      technologies, including, but
      not limited to Cookies and Web Beacons (explained below). We may collect your IP address, browsing
      behavior and device
      IDs. This information is used by us in order to enable us to better understand how our Services are
      being used by
      visitors and allows us to administer and customize the Services to improve your overall experience.
            </p>
      <h3>3. Information Collected From Other Sources</h3>
      <p>We may also receive information from other sources and combine that with information we collect
      through our Services.
      For example if you choose to link, create, or log in to your Pretzly account with a social media
      service, e.g. LinkedIn,
      Facebook or Twitter, or a payment provider, e.g. Apple Pay or Google Wallet, or if you engage with a
      separate App or
      Website that uses our API, or whose API we use, we may receive information about you or your
      connections from that Site
      or App. This includes, without limitation, profile information, profile picture, gender, user name,
      user ID associated
      with your social media account, age range, language, country, friends list, your contact names,
      e-mail addresses, phone
      numbers, and other identifiers and any other information you permit the social network to share with
      third parties. The
                data we receive is solely dependent upon your privacy settings with the social network.</p>
      <h3>4. Information Third Parties Provide</h3>
      <p>We may collect information about you from sources other than you, such as from social media websites
      (i.e., Facebook,
      LinkedIn, Twitter or others), blogs, analytics providers, business affiliates and partners and other
      users. This
      includes, without limitation, identity data, contact data, marketing and communications data,
      behavioral data, technical
                data and content data.</p>
      <h3>5. Aggregated Data</h3>
      <p>We may collect, use and share Aggregated Data such as statistical or demographic data for any
      purpose. Aggregated Data
      is de-identified or anonymized and does not constitute Personal Data as this data does not directly
      or indirectly reveal
      your identity. If we ever combine Aggregated Data with your Personal Data so that it can directly or
      indirectly identify
      you, we treat the combined data as PII which will only be used in accordance with this Privacy
                Policy.</p>
      <h3>6. Cookies, Log Files and Anonymous Identifiers</h3>
      <p>
        <ol type="a">
          <li> When you visit our Services, we may send one or more Cookies – small data files – to your
          computer to uniquely
          identify your browser and let us help you log in faster and enhance your navigation through
          the Sites. “Cookies” are
          text-only pieces of information that a website transfers to an individual’s hard drive or
          other website browsing
          equipment for record keeping purposes. A Cookie may convey anonymous information about how
          you browse the Services to us
          so we can provide you with a more personalized experience, but does not collect personal
          information about you. Cookies
          allow the Sites to remember important information that will make your use of the site more
          convenient. A Cookie will
          typically contain the name of the domain from which the Cookie has come, the “lifetime” of
          the Cookie, and a randomly
          generated unique number or other value. Certain Cookies may be used on the Sites regardless
          of whether you are logged in
                        to your account or not.</li>
          <li> Session Cookies are temporary Cookies that remain in the Cookie file of your browser
                        until you leave the Site.</li>
          <li> Persistent Cookies remain in the Cookie file of your browser for much longer (though how
          long will depend on the
                        lifetime of the specific Cookie).</li>
          <li> When we use session Cookies to track the total number of visitors to our Site, this is
          done on an anonymous aggregate
                        basis (as Cookies do not in themselves carry any personal data).</li>
          <li> We may also employ Cookies so that we remember your computer when it is used to return to
          the Site to help customize
          your Pretzly web experience. We may associate personal information with a Cookie file in
                        those instances.</li>
          <li> We use Cookies to help us know that you are logged on, provide you with features based on
          your preferences,
          understand when you are interacting with our Services, and compile other information
                        regarding use of our Services.</li>
          <li> Third parties with whom we partner to provide certain features on our Site or to display
          advertising based upon your
                        Web browsing activity use Cookies to collect and store information.</li>
          <li> Our Website may use remarketing services, to serve ads on our behalf across the internet
          on third party websites to
          previous visitors to our Sites. It could mean that we advertise to previous visitors who
          haven’t completed a task on our
          site. This could be in the form of an advertisement on the Google search results page or a
          site in the Google Display
          Network. Third-party vendors, including Google, use Cookies to serve ads based on your past
          visits to our Website. Any
          data collected will be used in accordance with our own privacy policy, as well as Google's
          privacy policies. To learn
          more, or to opt-out of receiving advertisements tailored to your interests by our third
          parties, visit the Network
                        Advertising Initiative at<a href="#"> www.networkadvertising.org/choices.</a></li>
          <li> Pretzly may use third-party services such as Google Analytics to help understand use of
          the Services. These services
          typically collect the information sent by your browser as part of a web page request,
          including Cookies and your IP
          address. They receive this information and their use of it is governed by their respective
          privacy policies. You may
          opt-out of Google Analytics for Display Advertisers including AdWords and opt-out of
          customized Google Display Network
          ads by visiting the Google Ads Preferences Manager here
                        <a href="#"> https://adssettings.google.com/authenticated?hl=en.</a> To provide
                        website visitors more choice on how their data is collected by Google Analytics, Google has
                        developed an Opt-out Browser
                        add-on, which is available by visiting Google Analytics Opt-out Browser Add-on here
                        <a href="#">https://tools.google.com/dlpage/gaoptout.</a> For additional information on how Google uses data
                        when you use Google
                        partners’ sites or apps, go here:<a href="#"> https://policies.google.com/technologies/partner-sites.</a>
          </li>
          <li> You can control the use of Cookies at the individual browser level. Use the options in
          your web browser if you do not
          wish to receive a Cookie or if you wish to set your browser to notify you when you receive a
          Cookie. You can easily
          delete and manage any Cookies that have been installed in the Cookie folder of your browser
          by following the
          instructions provided by your particular browser manufacturer. Consult the documentation
          that your particular browser
          manufacturer provides. You may also consult your mobile device documentation for information
          on how to disable Cookies
          on your mobile device. If you reject Cookies, you may still use our Website or App, but your
          ability to use some
                        features or areas of our Service may be limited</li>
          <li> Pretzly cannot control the use of Cookies by third parties (or the resulting
          information), and use of third party
                        Cookies is not covered by this policy.</li>
          <li> We automatically collect information about how you interact with our Services,
          preferences expressed, and settings
          chosen and store it in Log Files. This information may include internet protocol (IP)
          addresses, browser type, internet
          service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or
          clickstream data. We may combine
          this automatically collected log information with other information we collect about you. We
          do this to improve services
          we offer you, to improve marketing, analytics, or App/Site functionality, and to document
          your consent to receiving
          products, services or communications from us or our partners. If we link such information
          with personally identifiable
          information in a manner that identifies a particular individual, then we will treat all such
          information as PII for
                        purposes of this Privacy Policy.</li>
          <li> When you use our Services, we may employ Web Beacons (also known as clear GIFs or
          tracking pixels) to anonymously
          track online usage patterns. No Personally Identifiable Information from your account is
          collected using these Web
                        Beacons.</li>

        </ol>
      </p>
      <h3>7. Table of Cookies</h3>
      <p>Our Cookie table below lists some of the Cookies on our Website. Please note that the names of
      Cookies and other
      technologies may change over time.
            </p>
      <div class="container">
        <table style={{ wordBreak: 'break-all'}}>
          <tr>
            <th>Cookie Functions</th>
            <th>Cookie Purposes</th>

          </tr>
          <tr>
            <td>Analytics and Research</td>
            <td>We use Google Analytics to collect information about how visitors use the
            site. The cookies collect information in an anonymous form, including the
            number of visitors to the App, where visitors have come from and the pages
            they visited. You can opt out of Google Analytics by visiting www.google.com/
            settings/ads or by downloading the Google Analytics opt-out browser add-on
                    at https://tools.google.com/dlpage/gaoptout.<br></br>
                    For more information about Google's Privacy Policy, please visit http://
                    www.google.com/intl/en/policies/.</td>

                </tr>
        <tr>
          <td>Performance</td>
          <td>We need to use certain cookies and save data to your local storage devices
          to ensure our members have the best possible experience. They assist with
          your navigation of our site, ensuring pages load quickly and respond
                    promptly to your requests.</td>
        </tr>
        </table>
      </div>
      <h3>8. Device Information</h3>
      <p>When you use our Services through your computer, mobile phone or other device, we may collect
      information regarding and
      related to your device, such as hardware models and IDs, device type, operating system version, the
      request type, the
      content of your request and basic usage information about your use of our Services, such as date and
      time. In addition,
      we may collect information regarding application-level events and associate that with your account
      to provide customer
      service. We may also collect and store information locally on your device using mechanisms such as
      browser web storage
                and application data caches.</p>
      <h3>9. Location Information</h3>
      <p>When you use the Services we may collect your precise location data. We may also derive your
      approximate location from
                your IP address.</p>
      <h3>10. Call and SMS Data</h3>
      <p>In connection with providing customer service, we may receive call data, including the date and time
      of the call or SMS
      message, the parties phone numbers, and the content of the SMS message. You consent to the receipt,
      collection and
                storage of this information.</p>
      <h3>11. Children's Privacy</h3>
      <p>Our Services are intended for users who are at least the age of majority in their state of residence
      (eighteen (18)
      years of age or older in most jurisdictions). Furthermore, Pretzly does not knowingly collect
      personally identifiable
      information (PII) from persons under the age of 13. If you are under the age of 13, please do not
      provide us with
      information of any kind whatsoever. If you have reason to believe that we may have accidentally
      received information
      from a child under the age of 13, please contact us immediately at hello@pretzly.com. If we become
      aware that we have
      inadvertently received Personal Information from a person under the age of 13, we will delete such
      information from our
                records.</p>
      <h3>Protective Measures We Use</h3>
      <p>We protect your information using commercially reasonable technical and administrative security
      measures to reduce the
      risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use
      are firewalls and
      data encryption, physical access controls to our data centers, and information access authorization
      controls. Although
      we take measures to secure your information, we do not promise, and you should not expect, that your
      personal
      information, or searches, or other information will always remain secure. We cannot guarantee the
      security of our
      information storage, nor can we guarantee that the information you supply will not be intercepted
      while being
      transmitted to and from us over the Internet including, without limitation, email and text
      transmissions. The GDPR and
      certain jurisdictional laws may not allow the exclusion or limitation of certain liability for data
      security. If these
      laws apply to you, some or all of the above limitations may not apply to you. In the event that any
      information under
      our control is compromised as a result of a breach of security, we will take reasonable steps to
      investigate the
      situation and where appropriate, notify those individuals whose information may have been
      compromised and take other
                steps, in accordance with any applicable laws and regulations.</p>
      <h3>13. The Legal Basis and Reasons For Collection and Processing Your Personal Information</h3>
      <p>The legal basis upon which we rely for the collection and processing of your Personal Information
      under the GDPR are the
                following:</p>
      <p>
        <ol type="a">
          <li> When signing up to use our Services, you have given us explicit consent allowing Pretzly to
          provide you with our
          Services and generally to process your information in accordance with this Privacy Policy;
          and to the transfer of your
                        personal data to other jurisdictions including, without limitation, the United States;</li>
          <li> It is necessary for entering into, or performing, the obligations of a contract between
          you and Pretzly, including,
          without limitation, the Terms of Service agreement which may be accessed by you here
                        <a href="terms">(https://pretzly.com/terms);</a></li>
          <li> It is necessary registering you as a user, managing your account and profile, and
                        authenticating you when you log in.</li>

          <li> It is necessary for our legitimate interests in the proper administration of our website
          and business; analyzing the
          use of the website and our Services; assuring the security of our website and Services;
          maintaining back-ups of our
                        databases; and communicating with you;</li>
          <li> To resolve technical issues you encounter, to respond to your requests for assistance,
          comments and questions, to
          analyze crash information, to repair and improve the Services and provide other customer
                        support.</li>
          <li> To send communications via email and within the Services, including, for example,
          responding to your comments,
          questions and requests, providing customer support, and sending you technical notices,
          product updates, security alerts,
                        and administrative, billing and account management related messages.</li>
          <li> To send promotional communications that you have consented to receive or have expressed a
          legitimate interest in
                        receiving.</li>
          <li> It is necessary for our legitimate interests in the protection and assertion of our legal
          rights, and the legal
                        rights of others, including you;</li>
          <li>It is necessary for our compliance with certain legal provisions which may require us to
          process your personal data.
          By way of example, and without limitation, we may be required by law to disclose your
          personal data to law enforcement
          or a regulatory agency.
                    </li>
        </ol>
      </p>
      <h3>14. How We Use Information We Collect</h3>
      <p>Our primary purpose in collecting, holding, using and disclosing your Information is for our
      legitimate business
      purposes and to provide you with a safe, smooth, efficient, and customized experience. We will not
      rent, sell, lease or
      barter your information to anyone unless you grant us explicit permission for this information to be
      shared.
            </p>
      <p><b>We will use this information in order to:</b></p>
      <p>
        <ol type="a">
          <li> Provide users with our Services and customer support including, but not limited
          to,confirming emails related to our
                        services, reminders, confirmations, requests for information and transactions.</li>
          <li> Contact you and provide you with information.</li>
          <li> Analyze, improve and manage our Services and operations.</li>
          <li> Resolve problems and disputes, and engage in other legal and security</li>
          <li> Enforce our Terms of Service and any terms and conditions of any agreements for our
                        Services.</li>
        </ol>
      </p>
      <p><b>Additionally, we may use the information we collect about you to:</b></p>
      <p>
        <ol type="a">
          <li> Send you communications we think will be of interest to you, including information about
          products, services,
          promotions, news, and events of Pretzly and other companies, where permissible and according
                        to local applicable laws.</li>
          <li> Display advertising, including advertising that is targeted to you or other users based
          on your location, interests,
                        as well as your activities on our Services.</li>
          <li> Verify your identity and prevent impersonation, spam or other unauthorized or illegal
          activity including, without
                        limitation, infringement of intellectual property rights of third parties.</li>
          <li> We may transfer the information described in this Privacy Policy to, and process and
          store it in, the United States
          and other countries, some of which may have less protective data protection laws than the
          region in which you reside.
          Where this is the case, we will take appropriate measures to protect your personal
          information in accordance with this
                        Privacy Policy.</li>
        </ol>
      </p>
      <h3>15. How We Share Information We Collect</h3>
      <p>
        <ol type="a">
          <li>How We Share Information We Collect</li>
          <ol type="i">
            <li> With third party Service Providers to enable them to provide the Services you
                            request;</li>
            <li> With the general public if you submit content in a public forum, such as your
            reviews, comments or other features of
                            our Services that are viewable by the general public;</li>
            <li> With third parties with whom you choose to let us share information, for example
            other websites or apps that
            integrate with our API or Services, or those with an API or Service with which we
                            integrate</li>
            <li> With Pretzly subsidiaries and affiliated entities that provide services or conduct
            data processing on our behalf, or
                            for data verification, data centralization and/or logistics purposes;</li>
            <li> With affiliated entities that provide payment processing services on our behalf;</li>
            <li>With vendors, consultants, marketing partners, and other service providers who need
            access to such information to
                            carry out work on our behalf;</li>
            <li> In response to a request for information by a competent authority if we believe
            disclosure is in accordance with,
                            or is otherwise required by, any applicable law, regulation, or legal process;</li>
            <li> With law enforcement officials, government authorities, or other third parties if
            we believe your actions are
            inconsistent with our user agreements, Terms of Service, or policies, or to protect the
            rights, property, or safety of
                            Pretzly or others;</li>
            <li> In connection with, or during negotiations of, any merger, sale of company assets,
            consolidation or restructuring,
            financing, or acquisition of all or a portion of our business by or into another
                            company;</li>
            <li> If we otherwise notify you and you consent to the sharing; and</li>
            <li> In an aggregated and/or de-identified form which cannot reasonably be used to
            identify you. We only use such data in
            the aggregate form and our analytical services do not record any personal information.
                        </li>
          </ol>
          <li> We may disclose Your Information:</li>
          <ol type="i">
            <li> To any person who, in our reasonable judgment, is authorized to receive Your
            Information as your agent, including as
                            a result of your business dealings with that person (for example, your attorney);</li>
            <li> To our third-party vendors and service providers so that they may provide support
            for our internal and business
            operations, including for the processing of payments, handling of data processing, data
            verification, data storage,
            surveys, research, internal marketing, delivery of promotional, marketing and
            transaction materials, and our Services
            maintenance and security. These companies are authorized to use Your Information only as
            necessary to provide these
            services to us and are contractually obligated to keep Your Information confidential;
                        </li>
            <li> To third parties when you engage in certain activities through our Services that
            are sponsored by them, such as
            purchasing products or services offered by a third party, electing to receive
            information or communications from a third
            party, or electing to participate in contests, sweepstakes, games or other programs
            sponsored, in whole or in part, by a
            third party. When we disclose Your Information to these third parties, Your Information
            will become subject to the
            information use and sharing practices of the third party, and the third party will not
            be restricted by this Privacy
                            Policy with respect to its use and further sharing of Your Information;</li>
            <li> As required by law or ordered by a court, regulatory, or administrative agency;</li>
            <li> As we deem necessary, in our sole discretion, if we believe that you are violating
            any applicable law, rule or
            regulation, or are otherwise interfering with another's rights or property, including,
            without limitation, our rights or
                            property;</li>
            <li> If requested by authorities in the event of any act of terrorism or instance of
            local, regional or national
                            emergency;</li>
            <li> To enforce our Terms of Service, this Privacy Policy, and any other applicable
                            agreements and policies;</li>
            <li> To enforce or protect our legal rights.</li>
          </ol>
        </ol>
      </p>
      <h3>16. Sharing Information With Law Enforcement</h3>
      <p>
        <ol type="a">
          <li> Pretzly is committed to cooperating with law enforcement while respecting each
          individual’s right to privacy. If
          Pretzly receives a request for user account information from a government agency
          investigating criminal activity, we
          will review the request to be certain that it satisfies all legal requirements before
          releasing information to the
                        requesting agency.</li>
          <li> Furthermore, under 18 U.S.C. §§ 2702(b)(8) and 2702(c)(4) (Voluntary Disclosure Of
          Customer Communications or
          Records), Pretzly may disclose user account information to law enforcement, without a
          subpoena, court order, or search
          warrant, in response to a valid emergency when we believe that doing so is necessary to
          prevent death or serious
          physical harm to someone. Pretzly will not release more information than it prudently
          believes is necessary to prevent
                        harm in an emergency situation.</li>
        </ol>
      </p>
      <h3>17. Social Media Sharing</h3>
      <p>
        <ol type="a">
          <li> Our Services may now or in the future integrate with social sharing features and other
          related tools which let you
          share actions you take on our Services with other Apps, sites, or media, and vice versa.
          Your use of such features
          enables the sharing of information with your friends or the public, depending on the
          settings you establish with the
          social sharing service. Please refer to the privacy policies of those social sharing
          services for more information about
                        how they handle the data you provide to or share through them.</li>
          <li> Any information or content that you voluntarily disclose for posting publicly to a social
          sharing service becomes
          available to the public, as controlled by any applicable privacy settings that you set with
          the social sharing service.
          Once you have shared User Content or made it public, that User Content may be re-shared by
          others. If you remove
          information that you posted to the social sharing service, copies may still remain viewable
          in cached and archived
          pages, or if other users or third parties, using the social sharing service, have re-shared,
          copied or saved that User
                        Content.</li>
        </ol>
      </p>
      <h3>18. User Content and Public Sharing</h3>
      <p>If you share User Content or post a comment or message, or reply to a comment or message, that
      contains personally
      identifiable information to a page on our Services that is accessible to the public, third parties
      may use that
      information, or share it with other parties. As a result, you may receive unsolicited messages from
      other parties. Such
      User Content, comments or messages is not subject to this Privacy Policy and you share this
      information at your own
      risk. You will need to contact Pretzly in the event that you wish to have this personally
      identifiable information
                removed from our Service</p>
      <h3>19. Analytic Services Provided By Others</h3>
      <p>We may allow others to provide audience measurement and analytics services for us, to serve
      advertisements on our behalf
      across the Internet, and to track and report on the performance of those advertisements. These
      entities may use Cookies,
      Web Beacons, software development kits (SDKs), and other technologies to identify your device when
      you visit our Site
                and use our Services, as well as when you visit other online sites and services.</p>
      <h3>20. Links to Third-Party Websites</h3>
      <p>
        <ol type="a">
          <li> Our Services, as well as the email messages sent with respect to our Services, may contain
          links or access to
          websites operated by third parties that are beyond our control. Links or access to third
          parties from our Services are
          not an endorsement by us of such third parties, or their websites, applications, products,
          services, or practices. We
          are not responsible for the privacy policy, terms and conditions, practices or the content
          of such third parties. These
                        third-parties may send their own Cookies to you and independently collect data.</li>
          <li> If you visit or access a third-party Website, application or other property that is linked
          or accessed from our
          Services, we encourage you to read any privacy policies and terms and conditions of that
          third party before providing
          any personally identifiable information. If you have a question about the terms and
          conditions, privacy policy,
                        practices or contents of a third party, please contact the third party directly.</li>
        </ol>
      </p>
      <h3>21. Do Not Track Settings</h3>
      <p>Some web browsers may give you the ability to enable a "do not track" feature that sends signals to
      the websites you
      visit, indicating that you do not want your online activities tracked. This is different than
      blocking or deleting
      Cookies, as browsers with a "do not track" feature enabled may still accept Cookies. There currently
      is no accepted
      standard for how to respond to this signal, and we do not take any action in response to this
                signal.</p>
      <h3>22. International Privacy Practices</h3>
      <p>In order to provide our products and services to you, we may send and store your personal information
      outside of the
      country where you reside or are located, including to the United States. Accordingly, if you reside
      or are located
      outside of the United States, your personal information may be transferred outside of the country
      where you reside or
      are located, including countries that may not, or do not, provide the same level of protection for
      your personal
                information.</p>
      <p>We are committed to protecting the privacy and confidentiality of personal information when it is
      transferred. If you
      reside or are located within the European Union ("EU") or European Economic Area ("EEA") and such
      transfers occur, we
      take appropriate steps to provide the same level of protection for the processing carried out in any
      such countries as
                you would have within the EU and EEA to the extent feasible under applicable law</p>
      <p>By checking the box acknowledging that you have read and agree to this Privacy Policy, and providing
      explicit consent at
      the time of your registration for your account, users who reside or are located in countries outside
      of the United
      States, agree and consent to the transfer to and processing of personal information on servers
      located outside of the
      country where they reside, and assume the risk that the protection of such information may be
      different and less
                protective than those required under the laws of their residence or location.</p>
      <h3>23. Account Information</h3>
      <p>You may correct your account information at any time by logging into your online account. If you wish
      to cancel your
      account, please email us at hello@pretzly.com Please note that in some cases we may retain certain
      information about you
      as required by law, or for legitimate business purposes to the extent permitted by law. For
      instance, if you have a
      standing credit or debit on your account, or if we believe you have committed fraud or violated our
      Terms of Service, we
                may seek to resolve the issue before deleting your information.</p>
      <h3>24. Promotional Information Opt Out</h3>
      <p>You may opt out of receiving promotional messages from us at any time by following the instructions
      in those messages
      sent to you or by contacting us at any time using the Contact Us information at the end of this
      Privacy Policy. If you
      opt out, we may still send you non-promotional communications, such as those about your account,
      about Services you have
                requested, or our ongoing business relations.</p>
      <h3>25. Your Access and Rights to Your Personal Information</h3>
      <p><b>You have certain rights in relation to Personal Information we hold about you. You can exercise
      any of the following
      rights by contacting us using any of the methods in the Contact section below. We may need to
      request specific
      information from you to help us confirm your identity and ensure your right to access your
      Personal Data (or to exercise
      any of your other rights). This is a security measure to ensure that Personal Data is not
      disclosed to any person who
      has no right to receive it. We try to respond to all legitimate requests within one month.
      Occasionally it may take us
      longer than a month if your request is particularly complex or you have made a number of
      requests. In this case, we will
      notify you and keep you updated.
                </b>
      </p>
      <p>
        <ol type="a">
          <li><b> Right to Access Your Personal Data.</b> You have the right to access information held
                        about you for the purpose of
                        viewing and in certain cases updating or deleting such information. Furthermore, if you
                        prefer that Pretzly does not
                        share certain information as described in this Privacy Policy, you can direct Pretzly not to
                        share that information. We
                        will comply with an individual’s requests regarding access, correction, sharing and/or
                        deletion of the personal data we
                        store in accordance with applicable law. To make changes to your account affecting your
                        personal information contact us
                        at the email address in our Contact section below. For any deletion, non-sharing or update
                        request, we will make the
                        changes as soon as practicable, however this information may stay in our backup files. If we
                        cannot make the changes you
                        want, we will let you know and explain why.</li>
          <li><b> Right of Correction or Completion of Your Personal Data.</b> If personal information we
                        hold about you is not accurate,
                        out of date or incomplete, you have a right to have the data corrected or completed. To make
                        corrections to your account
                        please contact us at the email address in our Contact section below.b. Right of Correction
                        or Completion of Your Personal Data. If personal information we hold about you is not
                        accurate,
                        out of date or incomplete, you have a right to have the data corrected or completed. To make
                        corrections to your account
                        please contact us at the email address in our Contact section below.b. Right of Correction
                        or Completion of Your Personal Data. If personal information we hold about you is not
                        accurate, out of date or incomplete, you have a right to have the data corrected or
                        completed. To make corrections to your account please contact us at the email address in our
                        Contact section below.b. Right of Correction or Completion of Your Personal Data. If
                        personal information we hold about you is not accurate, out of date or incomplete, you have
                        a right to have the data corrected or completed. To make corrections to your account please
                        contact us at the email address in our Contact section below.b. Right of Correction or
                        Completion of Your Personal Data. If personal information we hold about you is not accurate,
                        out of date or incomplete, you have a right to have the data corrected or completed. To make
                        corrections to your account please contact us at the email address in our Contact section
                        below.</li>
          <li><b> Right of Erasure or Deletion of Your Personal Data.</b> In certain circumstances, you
                        have the right to request that
                        personal information we hold about you is deleted. If we cannot delete the information you
                        want, we will let you know
                        and explain why. To request information deletion please contact us at the email address in
                        our Contact section below.</li>
          <li><b> Right to Object to or Restrict Processing of Your Personal Data.</b> In certain
                        circumstances, you have the right to
                        object to our processing of your personal information. For example, you have the right to
                        object to use of your personal
                        information for direct marketing purposes. Similarly, you have the right to object to use of
                        your personal information
                        if we are processing your information on the basis of our legitimate interests and there are
                        no compelling legitimate
                        grounds for our processing which supersede your rights and interests. You may also have the
                        right to restrict our use of
                        your personal information, such as in circumstances where you have challenged the accuracy
                        of the information and during
                        the period where we are verifying its accuracy. To object to or restrict processing please
                        contact us at the email
                        address in our Contact section below.</li>
          <li><b> Right to Data Portability or Transfer of Your Personal Data.</b> You have the right to
                        be provided with a copy of the
                        information we maintain about you in a structured, machine-readable and commonly used
                        format. To receive a copy of the
                        information we maintain about you please contact us at the email address in our Contact
                        section below.</li>
          <li><b> Right to Withdrawal of Consent.</b> If you have given your consent to us to process and
                        share your Personal Information
                        after we have requested it, you have the right to withdraw your consent at any time. To
                        withdraw your consent please
                        contact us at the email address in our Contact section below.</li>
          <li><b> Right to Complain.</b> If you’re based in the EU, you can file a complaint with the
                        supervisory authority in your Member
                        State. By way of example, if you reside in the United Kingdom you can file a complaint with
                        the Information
                        Commissioner’s Office here https://ico.org.uk/.</li>
        </ol>
      </p>
      <h3>Your California Privacy Rights</h3>
      <p>
        <ol type="a">
          <li> California Civil Code Section 1798.83 entitles California residents to request information
          concerning whether a
          business has disclosed Personal Information to any third parties for their direct marketing
          purposes. California
          residents may request and obtain from us once a year, free of charge, information about the
          personal information, if
          any, we disclosed to third parties for direct marketing purposes within the immediately
          preceding calendar year. If
          applicable, this information would include a list of the categories of personal information
          that was shared and the
          names and addresses of all third parties with which we shared information within the
          immediately preceding calendar
                        year.</li>
          <li> If you are a California resident and would like to make such a request, please submit your
          request in writing to:
                        hello@pretzly.com</li>
        </ol>
      </p>
      <h3>27. Our Information Retention Policy</h3>
      <p>Unless you request that we delete certain information, we retain the information we collect for as
      long as your account
      is active or as needed to provide you services. Following termination or deactivation of your
      account, we will retain
      information for at least 3 years and may retain the information for as long as needed for our
      business and legal
      purposes. We will only retain your Personal Data for so long as we reasonably need to unless a
      longer retention period
                is required by law (for example for regulatory purposes).</p>
      <h3>28. Contact Us</h3>
      <p>If you have any questions or if you would like to contact us about our processing of your personal
      information,
      including exercising your rights as outlined above, please contact us by any of the methods below.
      When you contact us,
                we will ask you to verify your identity.</p>
      <p>Data Protection Officer</p>
      <p>Email: hello@pretzly.com.</p>
      <p>Our office address: 10715 Camarillo St, North Hollywood, CA 91602</p>
      <p><b>Last updated: January 04, 2021</b></p>
    </div>
  );
};

export default PrivacyContent;
