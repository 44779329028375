import React from 'react';
import homePretzel from './images/home_pretzel.svg';
import Footer from './Footer';
import { Box, Typography, useTheme } from '@mui/material';
import pretzels from './images/pretzels.svg';
import appStoreLogo from './images/app_store.svg';

const Home = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      mx="10%"
    >
      <Box
        component="img"
        src={homePretzel}
        alt="Pretzel Icon"
        width="auto"
        height="100px"
        mb={3}
      />

      <Typography variant="h1">

      <Box component="span" sx={{ color: 'white' }}>
        Less scrolling<br/><i>More </i><b>friend</b>-ing
      </Box>

      </Typography>

      <Box my={2} color="white">
      Build your neighborhood today.
      <br />
      </Box>

      <Box component="a" href="https://www.youtube.com/watch?v=ijBBYujUPBk">
        <img
          alt="App Store Logo"
          src={appStoreLogo}
          width="auto"
          height="50px"
        />
      </Box>

      <Box sx={{
        ml: '-10vw',
        mb: 4,
        height: '300px',
        width: '100vw',
        backgroundImage: `url('${pretzels}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center',
      }}/>

      <Footer showDisclaimer={true}/>
    </Box>
  );
};

export default Home;
