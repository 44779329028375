import React from 'react';
import Footer from './Footer';
import PrivacyContent from './PrivacyContent';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  content: {
    flex: '1 0 auto',
    margin: '0 10%',
  },
  title: {
    color: '#FFF',
    fontWeight: '600',
  },
  p: {
    color: 'RGB(172,192,247)',
  },
}));

const Privacy = () => {
  const classes = useStyles();

  const boxedTypography = (variant, className, content) => {
    return (
      <Box my={2}>
        <Typography className={className} variant={variant}>
          <div dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </Typography>
      </Box>
    )
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="left"
        >
          <Grid item>
            {boxedTypography('h5', classes.title, 'Privacy Policy')}
            <PrivacyContent />
          </Grid>
        </Grid>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Privacy; 