import pretzel_footer from './images/pretzel_footer.svg';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '40px',
    marginBottom: '40px',
    color: 'RGB(172,192,247)',
    fontFamily: `"Satoshi", sans-serif`,
    fontSize: 14
  },
  item: {
    marginLeft: '3%',
    marginRight: '3%',
  },
  imageItem: {
    width: '100%',
  },
  disclaimerItem: {
    marginTop: '56px',
    color: 'RGB(172,192,247)',
    fontFamily: `"Satoshi", sans-serif`,
    fontSize: 14
  },
  footerImage: {
    marginBottom: '-40px',
    marginTop: '40px',
    padding: '0',
    backgroundImage: 'url(' + pretzel_footer + ')',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    width: '100%',
    height: '102px',
  }
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div className="footer">
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
        <Grid container display="flex" justifyContent="center">
          <Grid item className={classes.item}>
            <Link to="/terms/">Terms</Link>
          </Grid>
          <Grid item className={classes.item}>
            <Link to="/privacy/">Privacy</Link>
          </Grid>
          <Grid item className={classes.item}>
            <a href="mailto:hello@pretzly.com">Contact</a>
          </Grid>
        </Grid>

        {
          props.showPretzels && (
            <Grid item className={classes.imageItem}>
              <div className={classes.footerImage}></div>
            </Grid>
          )
        }

        {
          props.showDisclaimer && (
            <Grid item className={classes.disclaimerItem}>
              <Typography>
                 
              </Typography>
            </Grid>
          )
        }
         </Grid>
    </div>
  );
};

export default Footer;
